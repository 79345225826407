import React from 'react';
import Global from '../layout/global';
import Form from '../components/Form';
import site from '../../config/site';
import { graphql } from 'gatsby';
import './tryit.scss';

const TryIt = ({ data }) => (
    <Global
        title="Demo CopperheadOS"
        background="/img/background/homepage2.jpg"
        description={ data.markdownRemark.frontmatter.description }
        keywords={ data.markdownRemark.frontmatter.keywords }
        page="tryit"
    >
        <div className="tryit-top">
            <h1 className="glitch" data-text="Demo">Demo CopperheadOS</h1>
            <div className="tryit-top-emails">
                <div className="tryit-top-email">
                    <div className="tryit-top-email-title">Business development contact</div>
                    <a href="mailto:info@copperhead.co?Subject=Business development opportunity">{ site.general_email }</a>
                    <p>Demos available for supported Google Pixels only.</p>
                </div>
            </div>
        </div>
        <div className="tryit-bottom">
            <div className="tryit-bottom-content">
                <div className="tryit-bottom-content-form">
                    <Form
                        id="getstarted"
                        // captcha={ true }
                        action="/api/tryit"
                        method="POST"
                        title="Get a CopperheadOS demo license"
                        sentMessage="Your message has been sent. A representative will be in touch with you shortly."
                    >
                        <fieldset>
                            <label htmlFor="name">Contact information</label>
                            <input type="text" aria-label="Name" name="name" placeholder="Name *" required />
                            <input type="email" aria-label="Email" name="email" placeholder="Email address *" required />
                            <input type="text" aria-label="Device" name="device" placeholder="Device" />
                        </fieldset>
                    </Form>
                </div>
                <div className="tryit-bottom-content-social">
                    <a rel="noopener noreferrer" href={ `https://www.${ site.social.facebook }` } target="_blank">
                        <div><img src="/img/icons/social/facebook.svg" alt="Facebook" /></div>
                        <span>{ site.social.facebook } </span>
                    </a>
                    <a rel="noopener noreferrer" href={ `https://www.twitter.com/${ site.social.twitter }` } target="_blank">
                        <div><img src="/img/icons/social/twitter.svg" alt="Twitter" /></div>
                        <span>@{ site.social.twitter }</span>
                    </a>
                    <a rel="noopener noreferrer" href={ `https://www.linkedin.com/company/${ site.social.linkedin }` } target="_blank">
                        <div><img src="/img/icons/social/linkedin.svg" alt="LinkedIn" /></div>
                        <span>LinkedIn – Copperhead Security</span>
                    </a>
                    <a rel="noopener noreferrer" href={ `https://www.reddit.com/${ site.social.reddit }` } target="_blank">
                        <div><img src="/img/icons/social/reddit.svg" alt="Reddit" /></div>
                        <span>{ site.social.reddit }</span>
                    </a>
                    <a rel="noopener noreferrer" href={ `https://www.youtube.com/c/CopperheadOS` } target="_blank">
                        <div><img src="/img/icons/social/youtube.svg" alt="YouTube" /></div>
                        <span>YouTube</span>
                    </a>
                    <a rel="noopener noreferrer" href={ `https://t.me/copperheadnews` } target="_blank">
                        <div><img src="/img/icons/social/telegram.svg" alt="Telegram" /></div>
                        <span>Telegram</span>
                    </a>
                </div>
            </div>
        </div>
    </Global>
)

export const pageQuery = graphql`
    query {
        markdownRemark(frontmatter: { path: { eq: "/tryit" } }) {
            html
            frontmatter {
                title
                date
                description
                keywords
            }
        }
    }
`;

export default TryIt;
